import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Box, Heading } from "theme-ui"
import { UserContext } from "../context/userContext"
import { i18nContext } from "../context/i18nContext"
import AuthLayout from "../components/authLayout"
import { Helmet } from "react-helmet"
import loginImage from "../images/login.jpg"
import SetPasswordForm from "../components/setPasswordForm"

const SetPassword = ({}) => {
  return (
    <AuthLayout locale={"en"} image={loginImage}>
      <i18nContext.Consumer>
        {t => (
          <>
            <Helmet>
              <title>{t.setNewPassword} | NetZero Tube</title>
            </Helmet>
            <Box sx={{ maxWidth: ["100%", "100%", "80%"], margin: "0 auto" }}>
              <Heading
                as="h1"
                variant="h1"
                sx={{
                  fontSize: [6, 7],
                  color: "dark",
                  fontWeight: 400,
                  strong: { fontWeight: 400, color: "primary" },
                  mb: [3, 4, 7],
                  mt: [7, 8, "10rem"],
                }}
                dangerouslySetInnerHTML={{ __html: t.setNewPassword }}
              />
              <SetPasswordForm />
            </Box>
          </>
        )}
      </i18nContext.Consumer>
    </AuthLayout>
  )
}

export default SetPassword
