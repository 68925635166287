import React, { useState } from "react"
import supabase from "../services/supabase"
import { Box, Button, Text, Input, Label } from "theme-ui"
import { i18nContext } from "../context/i18nContext"

const SetPasswordForm = ({}) => {
  const [password, setPassword] = useState("")
  const [error, showError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)
    showError(false)

    supabase.auth.updateUser({ password: password }).then(({ error }) => {
      if (error) {
        setLoading(false)
        showError(true)
        console.log("error", error)
      } else {
        setLoading(false)
        console.log("Reset instructions sent")
      }
    })
  }

  return (
    <i18nContext.Consumer>
      {t => (
        <Box as="form" onSubmit={handleSubmit}>
          <Box sx={{ position: "relative" }}>
            <Label variant="label.kicker" htmlFor="email">
              {t.newPassword} *
            </Label>
            <Input
              onChange={e => setPassword(e.target.value)}
              type="password"
              name="password"
              id="password"
              value={password}
              autoComplete="new-password"
              pattern=".{6,}" // 6 characters minimum
              required
              sx={{ mb: 4 }}
            />
            <Text
              as="p"
              variant="caption"
              sx={{ fontWeight: "normal", mt: [2] }}
            >
              6 characters minimum{" "}
            </Text>
          </Box>
          {error && (
            <Text as="p" sx={{ color: "red", mb: [3] }}>
              {t.signUpError}
            </Text>
          )}
          <Button sx={{ mt: [3] }}>
            {loading ? t.loading : t.setNewPassword}
          </Button>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

export default SetPasswordForm
